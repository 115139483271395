.footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: darkslategray;
    width: 100%;
  }

.footer > * {
    margin: 0.8rem;
}

.author {
    color: white;
    cursor: default;
    transition: color 0.2s ease-in-out;
}

.author:hover {
    color: lightgray;
}

.smile {
    display: inline-block;
    margin: 0;
    transition: transform 0.2s ease-in-out;
}

.author:hover .smile{
    transform: scale(1.5); 
}

.fa-github {
    cursor: pointer;
    font-size: 1.5rem;
}

.grow {
    transition: all 0.2s ease-in-out;
    color: white;
}

.grow:hover {
    transform: scale(1.5); 
    color: lightgray;
}


.pustishka {
    opacity: 0;
}

@media (max-width: 450px) {
    .pustishka {
        display: none;
    }
}