* {
  font-family: 'Lobster', cursive;
  box-sizing: border-box;
}

body {
  background-image: url(../images/background.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  position: relative;
}

.content-container {
  width: 50vw;
  background-color: darkslategray;
  margin: auto;
  text-align: center;
  border-radius: 20px;
}

.content-title {
  margin-top: 0;
  padding-top: 2vh;
  color: white;
  font-size: 2rem;
  font-weight: lighter;
  margin-bottom: 2vh;
}

.radio-title {
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.radio-container   {
  background-color: #D3D3D3;
  width: 80%;
  border-radius: 10px;
  margin: auto;
}

.radio-container-items   {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

input[type="radio"] {
  display: none;
}

label {
  display: flex;
  align-items: center;
  position: relative;
  color: black;
  margin: 0.2em;
  border: 2px solid darkslategray;
  border-radius: 5px;
  padding: 0.5em;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
}

label::before{
  width: 1em;
  height: 1em;
  content: "";
  border: 0.2em solid darkslategray;
  border-radius: 50%;
  margin-right: 0.5rem;
}

input[type="radio"]:checked + label {
  background-color: #6495ED;
  color: white;
}

input[type="radio"]:checked + label::before {
  height: 0.7rem;
  width: 0.7rem;
  border: 0.3rem solid white;
  background-color: #6495ED;
}

.input {
  display: block;
  margin: auto;
  width: 60%;
  height: 1.5rem;
  text-align: center;
  margin-top: 0.7rem;
  border-radius: 10px;
  background-color: #D3D3D3;
  color: black;
  font-size: 1rem;
  border: none;
}

input:focus{
  border: 2px;
  border-style: solid;
  border-color: #ADD8E6;
  outline: none;
}

input::placeholder {
  font-size: 1rem;
}

.find-button {
  display: inline-block;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  padding: 0 2rem;
  background-color: #ADD8E6;
  border-radius: 10px;
  height: 2rem;
  box-shadow: 0 0.3rem #999;
  border: none;
  font-size: 1.2rem;
  word-wrap: break-word;
  color: black;
}

.find-button:disabled {
  color: black;
}

.find-button:disabled:active {
  transform: translateY(0);
  box-shadow: 0 0.3rem #999;
}

.find-button:disabled:hover {
  background-color: #ADD8E6;
  cursor: default;
}

.find-button:hover {
  background-color: #6495ED;
  cursor: pointer;
}

.find-button:active {
  background-color: #6495ED;
  box-shadow: 0 0.1rem #666;
  transform: translateY(4px);
}

.image-container {
  width: 100%;
  text-align: center;
  min-height: 300px;
  margin-top: 3vh;
  padding-bottom: 4vh;
}

.image-result {
  width: 95%;
  margin: auto;
}

.image-description {
  position: relative;
  display: block;
  width: 50vw;
  background-color: darkslategray;
  margin: auto;
  text-align: center;
  border-radius: 20px;
  word-wrap: break-word;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.error-description {
  padding: 1rem;
  font-weight: bold;
  font-size: 2em;
  color: #FF5151;
  }

.image-source-description {
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  color: white;
}

.copy-image-src-btn {
  position: absolute;
  bottom: -10px;
  right: -10px;
  background-color: transparent;
  color: #999;
  cursor: pointer;
  font-size: 2rem;
  border: none;
}


.lds-dual-ring {
    padding-top: 3vh;
    display: inline-block;
    width: 80px;
    height: 80px;
  }

  .loading-description {
    margin-top: 5vh;
    color: white;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid white;
    border-color: white transparent white transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }  

  @media (max-width: 601px) {
    .content-container {
      width: 60vw;
    }
    .image-description {
      width: 60%;
    }
  }

  @media (max-width: 500px) {
    .content-container {
      width: 70vw;
    }
    .image-description {
      width: 70%;
    }
  }

  @media (max-width: 450px) {
    .input {
      width: 80%;
    }
  }

  @media (max-width: 320px) {
    .input::placeholder {
      font-size: 0.9rem;
    }
    .find-button {
      font-size: 1rem;
    }
  }

